<script setup>
import {BookmarkIcon, FlagIcon, HandThumbDownIcon, ShareIcon} from "@heroicons/vue/24/outline";
import {Link} from "@inertiajs/vue3";
import {usePostStore} from "@/Stores/PostStore";
import {storeToRefs} from "pinia";
import {useSwiperSlide} from "swiper/vue";

const swiperSlide = useSwiperSlide();
const postStore = usePostStore()
const {post, menuOpened} = storeToRefs(postStore)

</script>

<template>
	<teleport v-if="post && swiperSlide.isActive" to="body">
		<Transition leave-active-class="duration-200">
			<div v-show="menuOpened" aria-labelledby="slide-over-title"
			     aria-modal="true"
			     class="relative z-50"
			     role="dialog">

				<Transition
					enter-active-class="ease-out duration-300"
					enter-from-class="opacity-0"
					enter-to-class="opacity-100"
					leave-active-class="ease-in duration-200"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0"
				>
					<div v-show="menuOpened" class="fixed inset-0 bg-dark-950 opacity-25 transition-opacity"></div>
				</Transition>

				<div class="absolute inset-0 overflow-hidden">
					<div class="absolute inset-0 overflow-hidden">
						<div
							class="bottom-[3.5rem] fixed flex inset-y-0 items-end left-0 max-w-full pointer-events-none w-full">
							<Transition
								class="transform transition ease-in-out duration-500 sm:duration-700"
								enter-active-class="ease-in duration-300"
								enter-from-class="translate-y-full overflow-hidden"
								enter-to-class="translate-y-0 overflow-hidden"
								leave-active-class="duration-200 ease-out"
								leave-from-class="overflow-hidden translate-y-0"
								leave-to-class="overflow-hidden translate-y-full">
								<div v-if="menuOpened" class="pointer-events-auto w-screen mx-auto max-w-2xl exclude-poppit">
									<div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl rounded-t-lg">
										<div class="p-4 pb-0">
											<div class="flex items-start justify-between">
												<h2 id="slide-over-title"
												    class="text-base font-semibold leading-6 text-dark-900"></h2>
												<div class="ml-3 flex h-7 items-center">
													<button
														class="rounded-md bg-white text-dark-400 hover:text-dark-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														type="button"
														@click="postStore.toggleMenu(false)">
														<span class="sr-only">Close panel</span>
														<svg aria-hidden="true" class="h-6 w-6" fill="none"
														     stroke="currentColor"
														     stroke-width="1.5" viewBox="0 0 24 24">
															<path d="M6 18L18 6M6 6l12 12" stroke-linecap="round"
															      stroke-linejoin="round"/>
														</svg>
													</button>
												</div>
											</div>
										</div>
										<div class="relative pb-6 px-4 flex-1 space-y-2">
											<Link :href="route('post.collect.create', post.uuid)"
											      class="group relative flex gap-x-4 items-center rounded-lg hover:bg-dark-50">
												<div
													class="mt-1 flex p-2 flex-none items-center justify-center rounded-lg bg-dark-50 group-hover:bg-white">
													<BookmarkIcon
														class="h-4 w-4 text-dark-600 group-hover:text-indigo-600"/>
												</div>
												<div class="mt-1 font-semibold text-dark-900">
													Add to Collection
													<span class="absolute inset-0"></span>
												</div>
											</Link>
											<button
												class="group relative flex gap-x-4 items-center rounded-lg hover:bg-dark-50"
												type="button"
												@click="postStore.share()">
												<div
													class="mt-1 flex p-2 flex-none items-center justify-center rounded-lg bg-dark-50 group-hover:bg-white">
													<ShareIcon
														class="h-4 w-4 text-dark-600 group-hover:text-indigo-600"/>
												</div>
												<div class="mt-1 font-semibold text-dark-900">
													Share Post
													<span class="absolute inset-0"></span>
												</div>
											</button>
											<a :href="'mailto:reports@3sex.com?subject=Report Post&body=I want to report this post: ' + post.url" class="group relative flex gap-x-4 items-center rounded-lg hover:bg-dark-50"
												target="_blank"
												type="button">
												<div
													class="mt-1 flex p-2 flex-none items-center justify-center rounded-lg bg-dark-50 group-hover:bg-white">
													<FlagIcon
														class="h-4 w-4 text-dark-600 group-hover:text-indigo-600"/>
												</div>
												<div class="mt-1 font-semibold text-dark-900">
													Report
													<span class="absolute inset-0"></span>
												</div>
											</a>
										</div>
									</div>
								</div>
							</Transition>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	</teleport>
</template>
